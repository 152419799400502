<template>
  <base-card v-if="isLoggedIn">
    <form @submit.prevent="submitForm">
        <div class="form-control">
          <label for="weightint">Add Current Weight</label>
            <input type="number" id="weightint" v-model.trim="weightint" step="0.01"/>
        </div>
        <p
          v-if="!formIsValid"
        >Please enter a valid weight.</p>
        <base-button>Submit</base-button>
<!--        <base-button type="button" mode="flat" @click="switchAuthMode">{{ switchModeButtonCaption }}</base-button>-->
      </form>
  </base-card>
</template>

<script>

export default {
  data() {
    return {
      weightint: '',
      weight: '',
      formIsValid: true,
      isLoading: false,
      error: null,
    };
  },
  computed: {
    isLoggedIn() {
      return this.$store.getters.isAuthenticated;
    }
  },
  methods: {
    async submitForm() {
      this.weight = this.weightint;
      this.formIsValid = true;
      if (
        this.weight === '' ||
        this.weight.length < 2 || this.weight.length > 6
      ) {
        this.formIsValid = false;
        return;
      }

      this.isLoading = true;

      const actionPayload = {
        weight: this.weight,
      };

      const percentActionPayload = {
        percent: this.calculatePercent(this.$store.getters[`weights/startWeight`], this.weight)
      };

      try {
          await this.$store.dispatch('weights/addNewWeight', actionPayload);
      } catch (err) {
        this.error = err.message || 'Failed to submit weight, try later.';
      }

      try {
        await this.$store.dispatch('users/updatePercent', percentActionPayload);
      } catch (err) {
        this.error = err.message || 'Failed to submit weight, try later.';
      }

      this.isLoading = false;
      this.weight = "";
      this.weightint = "";
      this.weightdec = "";
    },
    handleError() {
      this.error = null;
    },
    calculatePercent(startWeight, currWeight){
        const loss = startWeight - currWeight;
        return  loss / startWeight * 100;
    },
  },
};
</script>

<style scoped>
form {
  margin: 1rem;
  padding: 1rem;
}

.form-control {
  margin: 0.5rem 0;
}

label {
  font-weight: bold;
  margin-bottom: 0.5rem;
  display: block;
}

input,
textarea {
  display: block;
  width: 100%;
  font: inherit;
  border: 1px solid #ccc;
  padding: 0.15rem;
}

input:focus,
textarea:focus {
  border-color: #3d008d;
  background-color: #faf6ff;
  outline: none;
}
</style>