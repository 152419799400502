export default {
    async updatePercent(context, data) {
            const userId = context.rootGetters.userId;

        const percentData = {
          id: userId,
          percent: data.percent,
          timeUpdated: new Date().getTime(),
        };

        const token = context.rootGetters.token;

        const response = await fetch(
          `https://valchallenge-default-rtdb.firebaseio.com/users/${userId}.json?auth=` +
            token,
          {
            method: 'PATCH',
            body: JSON.stringify(percentData)
          }
        );

        if (!response.ok) {
      // error ...
        }

        context.commit('updatePercent', {
        ...percentData,
    });
    },

    async firstLogin(context, data) {
        const userId = context.rootGetters.userId;

        const firstData = {
            firstName: data.firstName,
            lastName: data.lastName,
            dateCreated: new Date().getTime(),
            percent: 0,
        }

        const token = context.rootGetters.token;

        const response = await fetch(
          `https://valchallenge-default-rtdb.firebaseio.com/users/${userId}.json?auth=` +
            token,
          {
            method: 'PUT',
            body: JSON.stringify(firstData)
          }
        );

        if (!response.ok) {
      // error ...
        }

        context.commit('firstLogin', {
      ...firstData,
      id: userId,

    });

    },

    async loadUsers(context, payload) {

        if (!payload.forceRefresh && !context.getters.shouldUpdate) {
          return;
        }

        const response = await fetch(
          `https://valchallenge-default-rtdb.firebaseio.com/users.json`
        );
        const responseData = await response.json();

        if (!response.ok) {
          const error = new Error(responseData.message || 'Failed to fetch!');
          throw error;
        }

        const users = [];

        for (const key in responseData) {
          const user = {
            id: key,
            firstName: responseData[key].firstName,
            percent: responseData[key].percent,
            timeUpdated: responseData[key].timeUpdated,
          };
          users.push(user);
        }

        context.commit('setUsers', users);
        context.commit('setFetchTimestamp');
      },
};