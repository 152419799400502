<template>
  <li>
    <span><h3>{{ firstName }}: {{ percent.toFixed(2) }}% </h3></span>
      <br>
      <i>last updated {{formattedDateTime}}</i>
  </li>
  <hr>
</template>

<script>
import dayjs from 'dayjs';
export default {
  props: ['id', 'firstName', 'percent', 'timeUpdated'],
  computed: {
    formattedDateTime() {
      console.log("formatted time:" + this.timeUpdated);
      return dayjs(this.timeUpdated).format('MMM D, YYYY h:mm A');
    }
  }

}


</script>

<style scoped>

</style>