import { createStore } from 'vuex';

import authModule from './modules/auth/index.js';
import weightsModule from './modules/weights/index.js';
import usersModule from './modules/users/index'

const store = createStore({
  modules: {
    auth: authModule,
    weights: weightsModule,
    users: usersModule,
  }
});

export default store;