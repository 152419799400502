<template>
  <div>
    <section>
        <leaderboard></leaderboard>
        <add-weight></add-weight>
    </section>
  </div>
</template>

<script>
import Leaderboard from "@/pages/dashboard/Leaderboard";
import AddWeight from "@/pages/dashboard/AddWeight";

export default {
  name: "Dashboard",
  components: {
    Leaderboard,
    AddWeight
  }
}
</script>

<style scoped>

</style>