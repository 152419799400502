export default {

    async addNewWeight(context, data) {

        const userId = context.rootGetters.userId;

        const weightData = {
          id: userId,
          weight: parseFloat(data.weight).toFixed(2),
          timestamp: new Date().getTime(),
        };

        const token = context.rootGetters.token;

        const response = await fetch(
          `https://valchallenge-default-rtdb.firebaseio.com/weights/${userId}.json?auth=` +
            token,
          {
            method: 'POST',
            body: JSON.stringify(weightData)
          }
        );

        if (!response.ok) {
      // error ...
        }

        context.commit('addWeight', {
      ...weightData,
      id: userId
    });
    },

    async addStartWeight(context, data) {

        const userId = context.rootGetters.userId;

        const startWeightData = {
          startWeight: data.startWeight,
          timestamp: new Date().getTime(),
        };

        const token = context.rootGetters.token;

        const response = await fetch(
          `https://valchallenge-default-rtdb.firebaseio.com/startweights/${userId}.json?auth=` +
            token,
          {
            method: 'PUT',
            body: JSON.stringify(startWeightData)
          }
        );

        if (!response.ok) {
      // error ...
        }

        context.commit('addStartWeight', {
      ...startWeightData,
    });
    },

    async getUserStartWeight(context, payload) {

        if (!payload.forceRefresh && !context.getters.shouldUpdate) {
          return;
        }

        const userId = context.rootGetters.userId;

        const response = await fetch(
          `https://valchallenge-default-rtdb.firebaseio.com/startweights/${userId}.json`
        );
        const responseData = await response.json();

        if (!response.ok) {
        const error = new Error(responseData.message || 'Failed to fetch!');
          throw error;
        }

        const startWeightData = {
            id: userId,
            startWeight: responseData.startWeight
        };

        context.commit('addStartWeight', {
        ...startWeightData,
        });
        context.commit('setFetchTimestamp');
    },


    async calculateWeightPercent(context, data) {

        const userId = context.rootGetters.userId;

        const startWeightData = {
          startWeight: data.startWeight,
          timestamp: new Date().getTime(),
        };

        // eslint-disable-next-line no-undef

        const token = context.rootGetters.token;

        const response = await fetch(
          `https://valchallenge-default-rtdb.firebaseio.com/startweights/${userId}.json?auth=` +
            token,
          {
            method: 'PUT',
            body: JSON.stringify(startWeightData)
          }
        );

        if (!response.ok) {
      // error ...
        }

        context.commit('addStartWeight', {
      ...startWeightData,
    });
    },
};