export default {
  addWeight(state, payload) {
      state.weights.push(payload);
  },
  setFetchTimestamp(state) {
    state.lastFetch = new Date().getTime();
  },
  addStartWeight(state, payload) {
    state.weights = payload;
  }
};