export default {
  setUsers(state, payload) {
      state.users = payload
  },
  setFetchTimestamp(state) {
    state.lastFetch = new Date().getTime();
  },
  firstLogin(state, payload) {
    state.users.push(payload);
  },
  updatePercent(state, payload) {
    const idx = state.users.findIndex(u => u.id === payload.id);
    state.users[idx].percent = payload.percent;
    // state.users[idx].timeUpdated = payload.timeUpdated;
  },
};