<template>
  <div>
    <section>
      <base-card>
        <div><h3>LEADERBOARD</h3></div>
        <ul v-if="hasUsers">
          <leaderboard-item
            v-for="u in usersList"
            :key="u.id"
            :id="u.id"
            :first-name="u.firstName"
            :percent="u.percent"
            :time-updated="u.timeUpdated"
          ></leaderboard-item>
        </ul>
      </base-card>
    </section>
  </div>
</template>

<script>
import BaseCard from "@/components/ui/BaseCard";
import LeaderboardItem from "@/pages/dashboard/LeaderboardItem";
export default {
  components: {
    LeaderboardItem,
    BaseCard
  },
  data() {
    return {

    };
  },
  computed: {
    isLoggedIn() {
      return this.$store.getters.isAuthenticated;
    },
    usersList() {
      const users = this.$store.getters['users/users'];
      users.sort(function (a, b) {
        return b.percent - a.percent;
      });
      return users;
    },
    hasUsers() {
      return this.$store.getters['users/hasUsers'];
    },
  },
  created() {
    this.loadUsers();
    this.loadUserStartWeight();
  },
  methods: {
    async loadUsers(refresh = false) {
      this.isLoading = true;
      try {
        await this.$store.dispatch('users/loadUsers', {
          forceRefresh: refresh,
        });
      } catch (error) {
        this.error = error.message || 'Something went wrong!';
      }
      this.isLoading = false;
    },
    async loadUserStartWeight(refresh = false) {
      this.isLoading = true;
      try {
        await this.$store.dispatch('weights/getUserStartWeight', {
          forceRefresh: refresh,
        });
      } catch (error) {
        this.error = error.message || 'Something went wrong!';
      }
      this.isLoading = false;
    },
  }
}
</script>

<style scoped>
ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.controls {
  display: flex;
  justify-content: space-between;
}
</style>