export default {
  weights(state) {
    return state.weights;
  },
  startWeight(state){
    return state.weights.startWeight;
  },
  hasWeights(state) {
    return state.weights && state.weights.length > 0;
  },
  shouldUpdate(state) {
    const lastFetch = state.lastFetch;
    if (!lastFetch) {
      return true;
    }
    const currentTimeStamp = new Date().getTime();
    return (currentTimeStamp - lastFetch) / 1000 > 60;
  }
};